<template>
  <div class="print">
    <el-dialog id="dialog_c" custom-class="dialog_view_print" title="打印模板" class="dialog" center :visible.sync="isshow" :show-close="true" :close-on-click-modal="false">

      <!-- 打印表单 -->
      <div v-if="info" class="content_view_print">
        <div id="printDom" class="table_info p-lr-20 printDom" style="height:auto">
          <!-- 标题 start -->
          <div class="header weight f14 flex j-center column a-center m-bottom-20">
            <template v-for="(item,index) in info.titleArr">
              <span v-if="item.isChecked" :key="index">{{ item.label }}</span>
            </template>

          </div>
          <!-- 标题 end -->
          <!-- 表头 start -->
          <div class="flex f-warp adsadasd">

            <template v-for="(item,index) in info.header">
              <div v-if="item.isChecked" :key="index" :ref="'dom_' +index" class="item_ flex j-between a-center select_none" :style="{width: item.width +'%'}" @mouseleave="out" @mousemove="iii(item,index)">
                <div class="content flex">
                  <div>{{ item.label }}：</div>
                  <div>{{ item.value }}</div>
                </div>
                <!-- v-if="cureent === index" -->
                <div class="time_drag_line" @mousedown="dragControllerDiv(item,index)">
                  <div class="width_num">{{ item.width }}%</div>
                </div>
              </div>
            </template>
          </div>

          <!-- 表头 end -->
          <!-- 描述 start-->
          <div v-if="info.header_desc.isChecked" class="header weight f14 flex j-start a-center">
            {{ info.header_desc.label }}
          </div>
          <!-- 描述 end-->
          <!-- 表格 start-->
          <div class="m-top-10" style="width: 100%">
            <el-table
              ref="multipleTable"
              tooltip-effect="dark"
              show-header
              style="width: 100%"
              :data="[]"
              border
            >
              <template v-for="(item,index) in info.table">
                <af-table-column v-if="item.isChecked" :key="index" :prop="item.prop" :label="item.label" width="110px" />
              </template>
            </el-table>
          </div>
          <!-- 表格 end-->
          <!-- 合计 备注 start -->
          <div v-if="info.foot_table[0].isChecked" class="p-lr-20" style="height:25px;border:1px solid #EBEEF5;border-top:none;line-height:25px">备注：</div>
          <div v-if="info.foot_table[1].isChecked" class="p-lr-20" :style="info.foot_table[0].isChecked ? '': 'border-top:1px solid #EBEEF5;' " style="height:25px;line-height:25px;border-left:1px solid #EBEEF5;border-right:1px solid #EBEEF5;border-bottom:1px solid #EBEEF5;">总金额：</div>
          <!-- 合计 备注 end -->
          <!-- 描述 start-->
          <div v-if="info.footer_desc.isChecked" class="header weight f14 flex j-start a-center m-tb-10">
            {{ info.footer_desc.label }}
          </div>
          <!-- 描述 end-->
          <!-- 表头 start -->
          <div class="flex f-warp adsadasd">
            <template v-for="(item,index) in info.footer">
              <div v-if="item.isChecked" :key="index" :ref="'dom_' +index" class="item_ flex j-between a-center select_none" :style="{width: item.width +'%'}" @mouseleave="out1" @mousemove="iii1(item,index)">
                <div class="content flex">
                  <div>{{ item.label }}</div>
                  <div>{{ item.value }}</div>
                </div>
                <!-- v-if="cureent === index" -->
                <div class="time_drag_line1" @mousedown="dragControllerDiv1(item,index)">
                  <div class="width_num">{{ item.width }}%</div>
                </div>
              </div>
            </template>
          </div>

          <!-- 表头 end -->
        </div>
      </div>
      <div slot="footer" class="caozuo t-right p-top-20 t_line_s m-top-10">
        <el-button @click="edit">编辑模板内容</el-button>
        <!-- <el-button @click="isshow = false">取 消</el-button> -->
        <el-button type="primary" @click="savePrintTemplate">保存模板</el-button>
      </div>
    </el-dialog>
    <!-- 右侧div -->
    <div v-if="info" class="right_dig">
      <el-drawer
        title="编辑打印模板数据展示"
        :append-to-body="true"
        :visible.sync="innerDrawer"
        :with-header="false"
        size="50%"
      >
        <div class="content_print_view m-left-25">

          <div class="header_ ">
            <h3>设置打印标题</h3>
            <div class="flex">
              <div v-for="(item,index) in info.titleArr" :key="index" class="flex m-right-10 a-center">
                <el-checkbox v-model="item.isChecked" />
                <!-- -->
                <el-input :key="index" v-model="item.label" :disabled="item.prop !== 'desc'" class="m-left-10" size="mini" placeholder="请输入内容" />
              </div>

            </div>
          </div>

          <div class="header_ ">
            <h3>设置打印表头</h3>
            <div class="flex f-warp ">
              <vuedraggable v-model="info.header" style="width:100%" filter=".forbid" class="wrapper" animation="60">
                <transition-group id="transition-group" class="transition-group flex">
                  <template v-for="(item,index) in info.header">
                    <div :key="index" class="flex m-right-10 a-center m-bottom-10">
                      <el-checkbox v-model="item.isChecked" />
                      <el-input :key="index" v-model="item.label" class="m-left-10" size="mini" placeholder="请输入内容" />
                    </div>
                  </template>
                </transition-group>
              </vuedraggable>
            </div>
          </div>

          <div class="header_ ">
            <h3>设置打印表格上方描述文字</h3>
            <div class="flex f-warp ">
              <div class="flex m-right-10 a-center m-bottom-10" style="width:93.5%">
                <el-checkbox v-model="info.header_desc.isChecked" />
                <el-input v-model="info.header_desc.label" class="m-left-10" size="mini" placeholder="请输入内容" />
              </div>
            </div>
          </div>

          <div class="header_ ">
            <h3>设置打印表格</h3>
            <div class="flex f-warp ">
              <vuedraggable v-model="info.table" style="width:100%" filter=".forbid" class="wrapper" animation="60">
                <transition-group id="transition-group" class="transition-group flex">
                  <template v-for="(item,index) in info.table">
                    <div :key="index" class="flex m-right-10 a-center m-bottom-10">
                      <el-checkbox v-model="item.isChecked" />
                      <el-input :key="index" v-model="item.label" class="m-left-10" size="mini" placeholder="请输入内容" />
                    </div>
                  </template>
                </transition-group>
              </vuedraggable>

            </div>
            <div class="flex">
              <div v-for="(item,index) in info.foot_table" :key="index" class="flex m-right-10 a-center">
                <el-checkbox v-model="item.isChecked" />
                <el-input :key="index" v-model="item.label" disabled class="m-left-10" size="mini" placeholder="请输入内容" />
              </div>
            </div>
          </div>
          <div class="header_ ">
            <h3>设置打印表格下方描述文字</h3>
            <div class="flex f-warp ">
              <div class="flex m-right-10 a-center m-bottom-10" style="width:93.5%">
                <el-checkbox v-model="info.footer_desc.isChecked" />
                <el-input v-model="info.footer_desc.label" class="m-left-10" size="mini" placeholder="请输入内容" />
              </div>
            </div>
          </div>

          <div class="header_ ">
            <div class="flex a-center j-start">
              <h3 class="m-right-20">设置打印表底</h3>
              <!--   -->
              <el-button size="mini" style="height:30px" type="primary" @click="addFooterInfo">新增</el-button>
            </div>

            <div class="flex f-warp ">
              <vuedraggable v-model="info.footer" style="width:100%" filter=".forbid" class="wrapper" animation="60">
                <transition-group id="transition-group" class="transition-group flex">
                  <template v-for="(item,index) in info.footer">
                    <div :key="index" class="flex m-right-10 a-center m-bottom-10">
                      <el-checkbox v-model="item.isChecked" />
                      <el-input :key="index" v-model="item.label" class="m-left-10" size="mini" placeholder="请输入内容" />
                    </div>
                  </template>
                </transition-group>
              </vuedraggable>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>

import vuedraggable from 'vuedraggable'
import Sortable from 'sortablejs'
export default {
  name: 'Print',
  components: {
    vuedraggable
  },
  props: {
    custominfo: {
      type: Object,
      default: () => {}
    },
    companyid: {
      type: Number,
      default: 0
    },
    customerid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeName: '1',
      isshow: false,
      test: [],
      innerDrawer: false,
      info: '',
      cureent: '',
      cureent1: '',
      flag: true,
      header: [],
      footer: [],
      tempData: {

      }
    }
  },
  watch: {
    isshow(a) {
      console.log(a)
      console.log(this.companyid)
      console.log(this.customerid)
      // 调用数据获取模板，数据等信息
      if (this.custominfo) {
        console.log('(this.custominfo', this.custominfo)
        this.info = this.custominfo
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        // this.rowDrop()
        this.columnDrop()
      }, 100)
    })
  },
  updated() {
    if (this.flag) {
      // this.dragControllerDiv()
      const arr = this.info.header.filter(item => item.isChecked)
      const brr = this.info.footer.filter(item => item.isChecked)
      for (let i = 0; i < arr.length; i++) {
        this.dragControllerDiv(arr[i], i)
      }
      for (let i = 0; i < brr.length; i++) {
        this.dragControllerDiv1(brr[i], i)
        // this.dragControllerDiv1(this.info.footer[i], i)
      }
    }
  },
  methods: {
    // 列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
      // console.log('wrapperTr', wrapperTr)
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: ({ newIndex, oldIndex }) => {
          // console.log('newIndex', newIndex, oldIndex)
        }
      })
    },
    // 新增模板底部数据
    addFooterInfo() {
      this.info.footer.push({ label: '请输入名称', prop: '', value: '', isChecked: false, width: 20 })
    },
    edit() {
      this.info_ = JSON.parse(JSON.stringify(this.info))
      this.innerDrawer = true
    },
    out() {
      this.cureent = ''
    },
    out1() {
      this.cureent1 = ''
    },
    iii(item, index) {
      this.cureent = index
    },
    iii1(item, index) {
      this.cureent1 = index
    },
    dragControllerDiv(item, index) {
      const this_ = this
      const time_drag_line = document.getElementsByClassName('time_drag_line')
      // const width_body = document.body.clientWidth * 0.9
      // const dialog = document.getElementsByClassName('dialog')[0]
      const table_info = document.getElementsByClassName('adsadasd')[0]
      // const transition_group = document.getElementsByClassName('transition-group')[0]
      // const width__ = Math.floor(transition_group.getBoundingClientRect().width)
      time_drag_line[index].onmousedown = function(e) {
        const startX = e.clientX//
        time_drag_line[index].width = Math.floor((item.width / 100) * table_info.getBoundingClientRect().width)
        // const startX = Math.floor(e.clientX / (document.body.clientWidth - 100) * 100) // 百分比
        // const left = Math.floor((time_drag_line[index].getBoundingClientRect().left / document.body.clientWidth) * 100)
        table_info.onmousemove = function(ele) {
          const endX = ele.clientX
          let moveLen = time_drag_line[index].width + (endX - startX)
          moveLen = Math.floor(moveLen / (table_info.getBoundingClientRect().width) * 100)
          if (moveLen <= 15) {
            moveLen = 15
          }
          if (moveLen >= 80) {
            moveLen = 80
          }
          this_.$set(item, 'width', moveLen)
        }
        table_info.onmouseup = () => {
          table_info.onmousemove = null
          table_info.onmouseup = null
          this_.cureent = ''
          time_drag_line[index].releaseCapture && time_drag_line[index].releaseCapture() // 当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
        }
        return false
      }
    },
    dragControllerDiv1(item, index) {
      const this_ = this
      const time_drag_line = document.getElementsByClassName('time_drag_line1')
      // const width_body = document.body.clientWidth * 0.9
      // const dialog = document.getElementsByClassName('dialog')[0]
      const table_info = document.getElementsByClassName('adsadasd')[1]
      const dialog_c = document.getElementById('dialog_c')
      // const transition_group = document.getElementsByClassName('transition-group')[0]
      // const width__ = Math.floor(transition_group.getBoundingClientRect().width)
      time_drag_line[index].onmousedown = function(e) {
        const startX = e.clientX//
        time_drag_line[index].width = Math.floor((item.width / 100) * table_info.getBoundingClientRect().width)
        // const startX = Math.floor(e.clientX / (document.body.clientWidth - 100) * 100) // 百分比
        // const left = Math.floor((time_drag_line[index].getBoundingClientRect().left / document.body.clientWidth) * 100)
        table_info.onmousemove = function(ele) {
          const endX = ele.clientX
          let moveLen = time_drag_line[index].width + (endX - startX)
          moveLen = Math.floor(moveLen / (table_info.getBoundingClientRect().width) * 100)
          if (moveLen <= 15) {
            moveLen = 15
          }
          if (moveLen >= 100) {
            moveLen = 100
          }
          this_.$set(item, 'width', moveLen)
        }
        table_info.onmouseup = () => {
          table_info.onmousemove = null
          table_info.onmouseup = null
          this_.cureent1 = ''
          time_drag_line[index].releaseCapture && time_drag_line[index].releaseCapture() // 当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
        }
        dialog_c.onmouseup = () => {
          table_info.onmousemove = null
          table_info.onmouseup = null
          this_.cureent1 = ''
          time_drag_line[index].releaseCapture && time_drag_line[index].releaseCapture()
        }
        return false
      }
    },
    open(a) {
      console.log('dasdaaa', a)
      this.isshow = true
    },

    // 保存模板
    CompanyDeliveryReportCustomSave(info) {
      const obj = {
        'CompanyID': this.companyid,
        'CustomerID': this.customerid,
        'PrintJson': JSON.stringify(info)
      }
      this.$api.CompanyDeliveryReportCustomSave(obj).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.isshow = false
        return this.$message.success('保存成功')
      })
    },
    // 保存模板
    savePrintTemplate() {
      console.log('this.info', this.info)
      this.CompanyDeliveryReportCustomSave(this.info)
    }
  }
}
</script>
<style>
/* .dialog_view_print {
  display: flex;
  /*  margin:0 160px 48px */
/* .dialog_view_print {
  width: auto;
} */
@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .dialog_view_print {
     width: 90% !important;
  }

}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .dialog_view_print {
     width: 80% !important;
  }
}
@media only screen and  (min-width: 1501px) {
  .dialog_view_print {
     width: 50% !important;
  }
}
@media only screen and  (min-width: 500px) and (max-width: 1000px) {
  .dialog_view_print {
     width: 90% !important;
  }
}
@media only screen and  (min-width: 850px) and (max-width: 950px) {
  .dialog_view_print {
     width: 90% !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .dialog_view_print {
     width: 80% !important;
  }
}
</style>
<style scoped lang="scss">
.content_print_view {
  padding: 10px;

}

.right_dig {
  width: auto;
  height: auto;
  max-width: 500px;
  max-height: 800px;
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
  .item {
    width: 100px;
    height: 50px;
    margin-right: 5px;
  }
}
.el-dialog__body {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.table_info {
  max-width: 1000px;
  margin: 0 auto;
}

.testDom {
    border: 1px solid #ccc;
}
.transition-group {
    flex-wrap: wrap;
}
.item_ {
    margin-right:5px;
    cursor: move;
}
.content {
  flex:1;
  padding: 10px 0;
}
.hover {
box-shadow: 1px 0px 10px #CF5659;
}
.time_drag_line {
    min-width:20px ;
    height: 100%;
    background-color: #fff;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    cursor: e-resize;
    display: inline-block;
    position: relative;
    // padding: 10px 0;
    margin-right: 30px;
    .width_num {
      color: #ccc;
    }
}

.time_drag_line::before {
   content: '|';
   color: #ccc;
   width: 1px;
   padding: 10px 0;
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
}
.table_info {
  box-sizing: border-box;
}

.time_drag_line1{
    min-width:20px ;
    height: 100%;
    background-color: #fff;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    cursor: e-resize;
    display: inline-block;
    position: relative;
    // padding: 10px 0;
    margin-right: 30px;
    .width_num {
      color: #ccc;
    }
}
.time_drag_line1::before {
   content: '|';
   color: #ccc;
   width: 1px;
   padding: 10px 0;
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
}
</style>
